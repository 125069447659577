@import "./Const";

#quiz-setting-page {
    display: flex;
    justify-content: space-between;
    .quiz-settings-groups {
        width: 200px;
        margin: 15px 0;
        .groups {
            display: none;
            border: 1px solid $purple;
            padding: 10px;
            .groups-title {
                font-size: 18px;
                text-decoration: underline;
                margin-bottom: 5px;
            }
            .category{
                margin: 10px 0;
                padding: 5px;
                border: 1px solid;
                font-weight: bold;
                .category-name{
                    display: flex;
                    justify-content: space-between;
                }
            }
            .items {
                .item {
                    display: flex;
                    margin: 5px 0;
                    input {
                        border: none;
                        border-bottom: 1px solid $purple;
                        overflow: hidden;
                        padding: 2px;
                        outline: none;
                        background: none;
                        color: #000;
                    }
                    button {
                        background: transparent;
                        border: none;
                        border-bottom: 1px solid $purple;
                        color: red;
                        cursor: pointer;
                        outline: none;
                    }
                    &.add {
                        margin-top: 15px;
                        button {
                            color: green;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .form-item {
        margin: 15px 0;
    }
    .form-item-text,
    .form-item-select {
        label {
            display: inline-block;
            width: 115px;
            margin-left: 10px;
        }
        input,
        select {
            width: 200px;
            border: 1px solid $purple;
            font-size: 15px;
            padding: 5px 10px;
            outline: none;
        }
    }
}

.form-button {
    text-align: center;
    .save-settings {
        @include purple_btn();
    }
}

@media screen and (max-width: 750px){
    #quiz-setting-page{
        flex-direction: column;
        text-align: center;
        .quiz-settings-groups{
            margin: 20px auto;
        }
    }
}