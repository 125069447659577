$blue: #03004c;
$green: #5cb85c;
$orange: orange;
$bluelight: #012c6d;
$pink: #f12184;
$purple: #330330;

@mixin purple_btn() {
    background: rgba($blue, 0.8);
    //border: 2px solid $purple;
    width: 250px;
    text-align: center;
    font-size: 15px;
    padding: 15px;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
        background: rgba($purple, 0.3);
    }
    color: whitesmoke;
}
