@import "./Const";

#quiz-gamers-page {
    .add-game {
        @include purple_btn();
        font-size: 25px;
        width: 50%;
        margin: 0% 25% 0% 25%;
    }
    .add-file{
        margin: 5px 0px 8px 0px;
        display: inline-block;
        width: 15%;
        background: green;
        float: left;
    }
    .download{
        margin: 5px 0px 8px 0px;
        float: right;
        background: red;
    }
    .error-msg{
        text-align: center;
        color: red;
        margin: 10px 0;
        font-weight: bold;
    }
    .gamers-items {
        .game-item {
            display: flex;
            justify-content: space-between;
            border: 1px solid $purple;
            padding: 5px 0;
            margin: 10px 0;
            .item {
                margin: 0 5px;
            }
            label {
                margin-left: 5px;
            }
            input,
            select {
                width: 125px;
                border: none;
                border-bottom: 1px solid;
                outline: 0;
            }
        }
    }
}

.form-button {
    text-align: center;
    .save-gamers {
        /*padding: 3px 30px;
        font-size: 18px;
        margin-top: 2px;
        background: $bluelight;
        border: 1px solid;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        transition: 0.5s;
        color: whitesmoke;*/
        @include purple_btn()
    }
}

.mark::marker {
    color: blue;
    font-size: 1.2em;
}

@media screen and (max-width: 750px) {
    #quiz-gamers-page .gamers-items .game-item {
        flex-direction: column;
        text-align: center;
    }
}
