@import "./Const";

#stock-page{
    .title{
        text-align: center;
        font-size: 25px;
        font-weight: bold;
    }
    .action-btns{
        display: flex;
        margin: 10px 0;
        justify-content: space-between; 
        button{
            @include purple_btn();
            font-size: 18px;
            width: 47%;
        }
    }
    .stock-comment {
        padding: 10px;
        font-style: italic;
        font-weight: 600;
        font-size: x-large;
        text-align: center;
        border: groove;
    }
}

#stock-search,
#stock-search-lock{
    padding: 10px 35px;
    overflow: auto;
    //border: 2px solid $purple;
    margin: 10px 0;
    .row{
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        > *{
            margin: 0 5px;  
        }
        span{
            margin-left: 5px;
        }
        input{
            width: 100%;
            font-size: 16px;
            padding: 5px;
        }
        select{
            font-size: 16px;
        }
        &.row-2{
            select{
                width: 100%;
            }
        }
    }
    .advance-link{
        border: 2px solid $bluelight;
        width: 170px;
        padding: 0 5px;
        text-align: center;
        cursor: pointer;
    }
    button{
        background: $bluelight;
        border: 2px solid $bluelight;
        font-size: 20px;
        color: whitesmoke;
    }
}

#stock-page{
    .questions-list{
        input{
            position: absolute;
            left: -9999px;
            width: 0;
            height: 0;
            visibility: hidden;
        }
        input:checked{
            & ~ .question{
                &::after{
                    width: 100%;
                }
            }
        }
        .question{
            position: relative;
            width: 100%;
            &::after{
                content: '';
                background: rgba($purple, 0.5);
                position: absolute;
                -webkit-transition: inherit;
                transition: 0.35s;
                z-index: -1;
                top: 0;
                width: 0;
                height: 100%;
                left: 0;
            }
        }
    }
}