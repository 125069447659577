@import "./Const";

.progress-container {
    position: absolute;
    background-color: rgba(255,255,255,0.8);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
    top:0;
    left:0;
    bottom:0;
    right:0;
    height:100%;
    width:100%;
}

.fileContainer {
    box-shadow: none !important;
    margin: 0px !important;
    padding: 10px !important;

}

.chooseFileButton {
    color: black !important;
    border: 2px solid #e34ed8 !important;
    background: rgba($purple, 0.8) !important;
    border-radius: 0px !important;
    margin: 0px !important;
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    /*@ng*/
    display: none;
}

.chooseFileButtonInput {
    @extend .chooseFileButton;
    margin-top: 5px !important;
    margin-right: 0px !important;


}