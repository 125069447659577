@import "./Const";

#quiz-import-page {
    .example {
        @include purple_btn();
        font-size: 25px;
        margin: 25px 0;
        padding: 0;
        a {
            color: #000;
            text-decoration: none;
        }
    }
    .file {
        margin: 25px 0;
    }
    .save {
        text-align: center;
        button {
            background: $bluelight;
            border: none;
            font-size: 25px;
            padding: 5px 20px;
            cursor: pointer;
            &:hover {
                background: rgba($bluelight, 0.5);
            }
        }
    }
}

@media screen and (max-width: 750px) {
}
