@import "./Const";

body {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}
.MuiStepConnector-alternativeLabel {
  left: calc(50% + 20px)!important;
  right: calc(-50% + 20px)!important;
}

.App {
  display: flex;
  justify-content: center;
  /*pertty checkbox rtl */
  .pretty {
    margin-right: 0;
    outline: none;
    .state {
      label {
        &:before {
          border-color: $purple;
        }
        &:after,
        &:before {
          right: 0;
        }
      }
    }
    &.p-default {
      input:checked ~ .state {
        label:after {
          background-color: $purple !important;
        }
      }
    }
    &.p-switch {
      .state {
        &:before {
          top: 0;
          border-color: lighten($color: $purple, $amount: 25%);
        }
        label {
          &:after {
            background-color: gray;//lighten($color: $purple, $amount: 25%) !important;
          }
          &:after,
          &:before {
            right: 1px;
            top: 1px;
          }
        }
      }
      input:checked ~ .state {
        &:before {
          border-color: $purple;
        }
        label:after {
          right: 1em;
          background-color: $purple !important;
        }
      }
    }
  }
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .user-menu {
    width: 100%;
    padding-right: 50px;
    ul{
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      padding-right: 50px;
      list-style: none;
      li{
        cursor: pointer;
        a{
          color: #000;
          text-decoration: none;
        }
        &.user-menu-title {
          cursor: default;  
        }
      }
    }
    /*.user-menu-title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      cursor: pointer;
      &:hover {
        .triangular {
          border-top-color: lightgray;
        }
      }
      .triangular {
        display: inline-block;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-top: 8px solid gray;
        margin: 0 5px;
        transition: 0.5s;
      }
    }
    .user-menu-items {
      position: absolute;
      background: #fff;
      padding: 5px;
      border: 1px solid;
      width: 100%;
      margin-top: 10px;
      li{
        cursor: pointer;
        a{
          color: #000;
          text-decoration: none;
        }
      }
    }*/
  }
}

#logo {
  cursor: pointer;
  img {
    max-height: 60px;
  }
}

#contect {
  direction: rtl;
  width: 650px;
  margin: 0 50px; 
}

.red{
  color: red;
  font-size: large;
}

