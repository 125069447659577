@import "./Const";

.live{
    color: red !important;
}

#quiz-page {
    .quiz-name {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
    }
    .quiz-menu {
        ul {
            display: flex;
            justify-content: space-around;
            margin: 10px 0;
            padding: 0 10px;
            list-style: none;
            li {
                padding: 5px 10px;
                background: rgba($purple, 0.8);
                border: 1px solid;
                font-weight: bold;
                cursor: pointer;
                text-align: center;
                transition: 0.5s;
                color: whitesmoke;
                &.active,
                &:hover {
                    background: rgba($purple, 0.4);
                }
                &.return{
                    //width: 60px;
                }
            }
        }
    }
    .sub-menu {
        padding: 5px;
        border: groove;
    }
}

#quiz-main-page{
    //padding: 20px 40px;
    //height: calc(100vh - 100px);
    overflow: auto;
    //border: 2px solid $purple;
    text-align: center;
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li{
            color: whitesmoke;
            //width: calc(50% - 40px);
            width: 95%;
            background: $bluelight;
            margin: 20px;
            height: 50px;
            line-height: 50px;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                    background: rgba($bluelight, 0.5);
                }
        }
    }
}
.quiz-content {
    padding: 20px 40px;
    //height: calc(100vh - 200px);
    overflow: auto;
    //border: 2px solid $purple;
    font-size: 1.2em;
}
.hide{
    display: none;
}

.type-question{
    text-align: center;
    border: 1px solid #330330;
    padding: 10px;
}

#disable {
    display:none;
}
@media screen and (max-width: 750px) {
    #quiz-page {
        .quiz-menu {
            ul {
                li {
                    width: 40%;
                }
            }
        }
    }
}
