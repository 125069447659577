@import "./Const";

#quiz-question,
#stock-page {
    .top-btns {
        display: flex;
        justify-content: space-around;
        button {
            /*background: rgba($purple, 0.8);
            border: none;
            width: 250px;
            font-size: 25px;
            padding: 5px;
            color: whitesmoke;
            cursor: pointer;*/
            @include purple_btn();
            font-size: 25px;
        }
    }
    .questions-list {
        margin: 10px 0;
        .question {
            display: flex;
            margin: 0px 0px 10px 0;
            border: 1px solid $purple;
            width: 100%;
            .question-title {
                width: 100%;
                padding: 5px 10px;
                border-right: double;
            }
            .question-index{
                width: auto;
                padding: 3%;
                margin: auto;
            }
            .question-answers-type {
                width: 80px;
                text-align: center;
                padding: 5px 0;
                &.answers-type-1 {
                    background: $orange;
                }
                &.answers-type-2 {
                    background: $green;
                }
                &.answers-type-3 {
                    background: $bluelight;
                }
            }
            .actions {
                width: 130px;
                text-align: center;
                cursor: default;
                .btn {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 5px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .hand {
                padding: 10px;
            }
        }
    }
}

#question-page {
    .back {
        @include purple_btn();
    }
    .the-question {
        margin: 20px 0;
        display: flex;
        input {
            width: 100%;
            font-size: 1.2em;
            line-height: 1.7;
            padding: 0 5px;
            outline: none;
        }
        select {
            outline: none;
        }
    }
    .question-data {
        display: flex;
        justify-content: space-between;
        .tags-area {
            width: 220px;
            text-align: center;
            font-size: 0.5em;
        }
    }
    .ans-data{
        width: 100%;
    }
    .back{
        width: 100%;
    }
    .question-answers-type {
        label {
            display: inline-block;
            span {
                display: inline-block;
                padding: 5px 20px;
                margin: 0 10px;
                border-radius: 10px;
                cursor: pointer;
            }
            .answers-type-1 {
                border: 2px solid #000000;// @ng $orange;
            }
            .answers-type-2 {
                border: 2px solid #000000;// @ng $green;
            }
            .answers-type-3 {
                border: 2px solid #000000;// @ng $bluelight;
            }
            input {
                position: absolute;
                left: -9999px;
                width: 0;
                height: 0;
                visibility: hidden;
                &:checked {
                    & ~ .answers-type-1 {
                        background: $orange;
                    }
                    & ~ .answers-type-2 {
                        background: $green;
                    }
                    & ~ .answers-type-3 {
                        background: $bluelight;
                    }
                }
            }
        }
    }
}
.error-msg {
    color: red;
    font-weight: 600;
    margin-bottom: -10px;
    padding-top: 10px;
}

.ans-input{
    width: 90%;
    //font-size: 1.2em;
}
.full{
    width: 98%;
}


.fieldset-wrap {
    border: 1px solid $purple;
    margin: 15px 0;
    padding: 5px;
    .fieldset-title {
        font-weight: bold;
        cursor: pointer;
        &:before {
            content: "";
            display: inline-block;
            border-right: 10px solid $purple;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            margin-left: 5px;
            transition: 0.5s;
        }
        &.open {
            &:before {
                transform: rotate(-90deg);
            }
        }
    }
}

.add-answer {
    font-weight: bold;
    cursor: pointer;
}

.tag {
    text-align: right;
    display: flex;
    justify-content: space-between;
}

.reorder {
    margin-top: 5px;
    justify-content: space-between;
    text-align: center;
}

.reorderButton {
    background: $bluelight;
    border: 1px solid;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: 0.5s;
    color: whitesmoke;
}

.preview-question {
    width: 100px;
    margin: 0 !important;
    padding: 0 !important;
}

@media screen and (max-width: 750px) {
    #quiz-question,
    #stock-page {
        .top-btns button {
            width: 100px;
            font-size: 18px;
            margin: 0 20px;
        }
        .questions-list {
            .question {
                display: block;
                &:before {
                    content: "";
                    display: block;
                }
                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
                .question-title {
                    border-bottom: 1px solid $purple;
                }
                .question-answers-type,
                .actions {
                    float: right;
                    width: 50%;
                }
            }
        }
    }
    #question-page {
        .question-data {
            flex-direction: column;
            .question-answers-type {
                text-align: center;
                label span {
                    padding: 3px 5px;
                    margin: 0 5px;
                }
            }
            .tags-area {
                margin: 0 auto;
                .react-tags {
                    margin: 0;  
                }
            }
        }
    }
}

.stock-item {
    height: 50px;
    width: 100%;
    background-color: rgba($purple, 0.8);
    margin: 10px;
}