@import "./Const";

#main-page {
    padding: 20px 40px;
    //height: calc(100vh - 80px);
    overflow: auto;
    //border: 2px solid $purple;
    .quiz-list {
        text-align: center;
        .quiz-list-title {
            font-size: 30px;
        }
        ul {
            padding: 0;
            list-style: none;
            width: 250px;
            margin: 0 auto;
            li {
                color: whitesmoke;
                margin: 10px 0;
                padding: 10px;
                font-size: 20px;
                border: 2px solid $bluelight;
                background: $bluelight;
                cursor: pointer;
                transition: 0.5s;
                &:hover {
                    background: rgba($bluelight, 0.5);
                }
            }
        }
        p {
            margin: 0;
            font-size: large;
        }
    }
    .add{
        border-radius: 50%;
        width: 75px;
        height: 75px;
        margin: 0% 43% 0%;
        color: whitesmoke;
        padding: 6px;
        font-size: 20px;
        background: #012c6d;
        cursor: pointer;
        text-align: center;
        font-size: xx-large;
    }
}

#main-btn{
    width: 50%;
    margin: 2% 25% 2% 10%;
    padding: 2%;
    font-size: 20px;
    border: 2px solid $bluelight;
    background: $purple;
    color: white;
    cursor: pointer;
    font-weight: bold;
    transition: 0.5s;
}

.full-width {
    width: 100%;
}
